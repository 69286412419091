import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeBannerImage from '../assets/images/home/landing-row-bg.png';
import { Link } from 'gatsby';
import '../assets/css/styles.css';
import { launchVideos } from '../data';
import { lng } from '../components/essentials';
import playButton from '../assets/images/icons/play-2.svg'

export default function LivePage(props) {
    return <Layout>
        <SEO title="Live" />
        <main style={{ paddingTop: '100px' }}>
            <div
                className="w-100"
                style={{
                    height: '100vh',
                    backgroundImage: `url(${HomeBannerImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            >
                <div className="h-100 w-100 flex items-center justify-center">
                    <div className="uk-position-left uk-position-center uk-panel ph5 hero-text-container">
                        <div data-uk-lightbox="animation: slide" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                            {launchVideos.map(video => (
                                <a className="uk-button" href={`${video.URL}`}>
                                    <div className="animated-card animated-card-overlay-trigger">
                                        <div className="uk-inline w-100">
                                            <img src={`${video.Thumbnail.publicURL}`} className="w-100" alt=""/>
                                            <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                                                <img src={playButton} width="50px" height="50px" alt="play button"/>
                                            </div>
                                        </div>
                                    </div>

                                    <h4 className="white mt2 f5">{lng(video.Title, video.arabic_title)}</h4>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="absolute ml4 mb4 bottom-0 left-0">
                    <Link to="/gallery/live">
                        <button className="uk-button uk-button-default" style={{ background: 'rgba(0,0,0,0.5)', color: '#fff' }}>
                            <span uk-icon="icon: triangle-right; ratio: 2" style={{ marginLeft: '-20px' }}></span>
                            <span className="f5 b">Watch Live coverage</span>
                        </button>
                    </Link>
                </div>
            </div>
        </main>
    </Layout>
}